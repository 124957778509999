//@ts-check
import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <Link to="/demo">Contacto</Link>
        </li>
        
        <li>
          <Link to="/ayuda">Preguntas Frecuentes</Link>
        </li>
        <li>
          <a href="https://support.getscreenfox.com/">Soporte</a>
        </li>
        <li>
          <a href="https://app.getscreenfox.com/">Iniciar sesión</a>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;