//@ts-check
import React from 'react';
import Header from './Header';
import FooterMenu from './FooterMenu';

import '../styles/style.scss'

const Layout = ({ children }) => (
  <>
    <Header navPosition="right" className="reveal-from-bottom" />
    <main className="site-content">
      {children}
    </main>
    <FooterMenu />
  </>
);

export default Layout;  